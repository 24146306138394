<template>
  <div class="live-course-record">
    <p class="activatedAt">{{ activatedAt }}</p>
    <p class="recordContent m-0">{{ recordContent }}</p>
  </div>
</template>

<script>
export default {
  props: {
    record: {
      type: Object,
      required: true,
    },
  },
  computed: {
    activatedAt() {
      return this.$day(this.record.activatedAt).format('YYYY-MM-DD');
    },
    plans() {
      return this.$store.getters['course/plans'] || [];
    },
    recordContent() {
      let text = '';

      if (this.record.addDay) {
        if (this.record.addDay < 30) {
          text = `+${this.record.addDay}${this.$t('天')}`;
        } else {
          text = `+${Math.ceil(this.record.addDay / 30)}${this.$t('個月')}`;
        }
        if (this.record.billingType === 'compensate') {
          text += `（${this.$t('贈送')}）`;
        } else {
          if (this.record.coursePlan) {
            const selectedPlan = this.plans.find(
              (plan) => plan.id === this.record.coursePlan
            );
            text += `（${this.$t('購買')}${selectedPlan?.name}）`;
          } else {
            text += `（${this.$t('購買直播講堂')}${
              this.record?.product.name
            }）`;
          }
        }
      } else {
        text = this.$t('必修直播講堂（購買兒童啟蒙班）');
      }

      return text;
    },
  },
};
</script>

<style lang="scss" scoped>
.live-course-record {
  padding: 11px 16px;
  border-bottom: 1px solid $line-2;
  color: $font-grayscale-2;
  .activatedAt {
    color: $font-grayscale-3;
    margin: 0;
  }
}
@media screen and (min-width: 768px) {
  .live-course-record {
    color: $font-normal;
    display: flex;

    .activatedAt {
      color: $font-normal;
      margin-right: 8px;
    }
  }
}
</style>
