<template>
  <div
    class="live-course-records d-flex flex-column h-100 position-relative"
    :class="{'overflow-hidden': $device.isMobile}"
  >
    <base-header
      :title="$t('直播時數')"
      :left-arrow="true"
      :breadcrumb="breadcrumb"
      :mode="$device.isMobile ? 'secondary' : 'primary'"
    ></base-header>

    <loading-wrapper
      v-if="isPageLoading"
      :style="!$device.isMobile ? 'min-height: 400px' : ''"
    ></loading-wrapper>

    <div v-else class="wrapper scroll-bar">
      <div class="live-course-records-container">
        <div
          class="expired-time-card bg-white d-flex align-items-center justify-content-center mb-3 mb-md-4 p-4 p-md-4"
        >
          <div class="text">
            <span class="title"> {{ $t('直播講堂到期日') }}：</span>
            <span class="time">{{ expiredAt }}</span>
            <b-button
              variant="primary"
              size="md"
              class="mx-auto d-block"
              :class="{'mt-3': $device.isMobile}"
              @click="onClick2Web"
            >
              {{ $t('前往官網購買時數') }}
            </b-button>
          </div>
        </div>
        <div class="record-card bg-white overflow-hidden mb-md-4 p-md-4">
          <div class="title font-weight-bold">{{ $t('歷史紀錄') }}</div>

          <LiveCourseRecord
            v-for="record in records"
            :key="record.activatedAt"
            :record="record"
          ></LiveCourseRecord>
        </div>
        <div
          class="rule-card bg-white rounded-10 overflow-hidden p-3 p-md-4 m-3 m-md-0 mb-md-0"
        >
          <div
            class="title rounded-10 bg-bgsection text-primary font-weight-bold text-18 mb-2 mb-md-3"
          >
            {{ $t('直播講堂規則說明') }}：
          </div>
          <div class="content">
            <ol class="mb-0">
              <li v-for="rule in rules" :key="rule">
                {{ rule }}
              </li>
            </ol>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseHeader from '@/components/Base/BaseHeader';
import LiveCourseRecord from '@/components/Personal/LiveCourseRecord.vue';
import LoadingWrapper from '@/components/Base/LoadingWrapper.vue';
import {manifest, stage} from '@/constant/env.js';
import {Browser} from '@capacitor/browser';

export default {
  components: {
    BaseHeader,
    LiveCourseRecord,
    LoadingWrapper,
  },
  data() {
    return {
      isPageLoading: false,
      rules: [
        this.$t(
          '計算方式：以每月 30 天，不分大小月的方式來計算。如有購買多堂課程或續購直播講堂方案，時數將自動累加延長。'
        ),
        this.$t(
          'LIVE結束：每個直播講堂結束後將保留 90 天。若錯過喜歡的講堂，可以在直播列表的篩選器中，選擇時間的【已結束（可播放的課程）】分類，找到該講堂點擊【播放】觀看喔！'
        ),
        this.$t('到期：直播講堂到期後，將無法觀看任何內容。'),
      ],
      breadcrumb: [
        {
          name: this.$t('個人'),
          enable: true,
          router: 'personal',
        },
        {
          name: this.$t('直播時數'),
          enable: true,
          router: 'live-course-record',
        },
      ],
    };
  },
  computed: {
    purchaseLogs() {
      return this.$store.getters['user/purchaseLogs'];
    },
    records() {
      const _purchaseRecords = this.purchaseLogs || [];
      return _purchaseRecords
        .filter(
          (record) =>
            record.item === 'hjjLiveCourse' ||
            record.product?.id.includes('kid2week')
        )
        .sort((a, b) => b.activatedAt - a.activatedAt);
    },
    expiredAt() {
      return this.records.length === 0 ||
        this.userData.liveCourseEndAt <= 0 ||
        this.$day().isAfter(this.userData.liveCourseEndAt)
        ? '-'
        : this.$day(this.userData.liveCourseEndAt).format('YYYY-MM-DD');
    },
    plans() {
      return this.$store.getters['course/plans'] || [];
    },
    userData() {
      return this.$store.getters['user/userData'];
    },
  },
  created() {
    if (this.purchaseLogs != null) {
      return;
    }
    this.getOwnPurchaseLog();
  },
  methods: {
    async getOwnPurchaseLog() {
      try {
        this.isPageLoading = true;
        await this.$store.dispatch('user/getOwnPurchaseLog');
        this.isPageLoading = false;
      } catch (error) {
        this.$Message.error('直播時數載入失敗');
      }
    },
    async onClick2Web() {
      let url = `${manifest.homePageUrl}/courses?coursesType=isLiveCourse`;

      if (stage === 'beta') {
        url =
          'http://ec2-13-230-41-83.ap-northeast-1.compute.amazonaws.com/courses?coursesType=isLiveCourse';
      }
      await Browser.open({
        url,
        presentationStyle: 'popover',
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.live-course-records {
  .wrapper {
    overflow: overlay;
  }
  .expired-time-card {
    background: white;
    .text {
      font-weight: bold;
      font-size: 18px;
      line-height: 30px;
    }
  }
  .record-card {
    line-height: 28px;
    color: $font-grayscale-1;
    .title {
      padding: 11px 16px;
      border-bottom: 1px solid $line-2;
    }
  }
  .rule-card {
    .title {
      padding: 5px 16px;
      line-height: 30px;
    }
    ol {
      padding: 0px 8px 0px 32px;
    }
  }
}

@media screen and (min-width: 768px) {
  .live-course-records {
    .wrapper {
      height: calc(100vh - 80px);
    }
    .live-course-records-container {
      max-width: 720px;
      margin: 40px auto;
      padding: 0 16px;
    }
    .expired-time-card,
    .rule-card,
    .record-card {
      border-radius: 10px;
      box-shadow: 0px 2px 7px rgba(199, 199, 199, 0.3);
    }
    .expired-time-card {
      .text {
        width: 100%;
      }

      .title {
        width: 100%;
        display: block;
        color: $primary;
        padding: 5px 16px;
        border-bottom: none;
        background: $bgsection;
        border-radius: 10px;
      }
      .time {
        display: block;
        padding: 10px 16px;
      }
    }
    .record-card {
      .title {
        color: $primary;
        padding: 5px 16px;
        border-bottom: none;
        background: $bgsection;
        border-radius: 10px;
        line-height: 30px;
      }
    }

    .rule-card {
      ol {
        font-size: 18px;
        line-height: 30px;
        padding: 0px 0px 0px 24px;
      }
    }
  }
}

@media screen and (min-width: 1200px) {
  .live-course-records {
    .live-course-records-container {
      max-width: 1140px;
    }
  }
}
</style>
